import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/header/header.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/briefly/components/briefly-section/briefly-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/cities-gallery/components/cities-gallery-section/cities-gallery-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/root-page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/home-intro/components/home-intro-section/home-intro-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/home-page-footer-section/home-page-footer-section.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/in-the-press/components/in-the-press-section/in-the-press-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/popular-cities/components/popular-cities-section/popular-cities.module.scss");
