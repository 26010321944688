import ListingGridItem from '@/features/global-search-banner/components/listing-grid-item/listing-grid-item';
import { DEFAULT_LISTING_PART_VARIANTS } from '@/features/listing/constants/listing-constants';
import getTypedObjectKeys from '@/shared/utils/get-typed-object-keys';

import styles from './listing-grid.module.scss';

export const SERVICE_TYPE_MAP_MAIN = getTypedObjectKeys(DEFAULT_LISTING_PART_VARIANTS);

export default function ListingGrid() {
  return (
    <div className={styles.listingGrid}>
      {SERVICE_TYPE_MAP_MAIN.map((serviceType) => (
        <ListingGridItem
          key={serviceType}
          name={serviceType}
        />
      ))}
    </div>
  );
}
