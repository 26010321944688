'use client';

import {
  useEffect,
  useState,
} from 'react';

import ListingIconBox from '@/features/listing/components/listing-icon-box/listing-icon-box';
import { DEFAULT_LISTING_PART_VARIANTS } from '@/features/listing/constants/listing-constants';
import { type ListingType } from '@/features/listing/types/listing-types';
import { getCookieCurrentPlace } from '@/features/place/cookies/cookie-current-place';
import placeNameToUrl from '@/features/place/utils/place-name-to-url';

interface ListingGridItemProps {
  name: ListingType;
}

export default function ListingGridItem(props: ListingGridItemProps) {
  const { name } = props;

  const [link,
    setLink] = useState<string>(`/${DEFAULT_LISTING_PART_VARIANTS[name]}/anywhere`);

  useEffect(() => {
    const currentPlace = getCookieCurrentPlace();

    if (currentPlace) {
      const placeLink = placeNameToUrl(currentPlace.fullName);

      setLink(`/${DEFAULT_LISTING_PART_VARIANTS[name]}/${placeLink}`);
    }
  }, [name]);

  return (
    <ListingIconBox
      listingType={name}
      href={link}
    />
  );
}
