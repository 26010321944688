'use client';

import cx from 'classix';
import { setCookie } from 'cookies-next/client';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

import useAppFlyerLink from '@/features/roomster-app/hooks/use-app-flyer-link';
import { ROUTER_LINKS } from '@/shared/constants/links-constants';
import { SECONDS } from '@/shared/constants/time-constants';

import styles from './create-listing.module.scss';

interface CreateListingProps {
  isShow: boolean
}

export default function CreateListing(props: CreateListingProps) {
  const { isShow } = props;
  const t = useTranslations('home.listing.create');

  const listPlaceAppFlyerLink = useAppFlyerLink('mobile', 'mobileListPlace', ROUTER_LINKS.phoneAuth);
  const findPlaceAppFlyerLink = useAppFlyerLink('mobile', 'mobileFindPlace', ROUTER_LINKS.phoneAuth);

  const handleListPlaceAuth = () => {
    setCookie('authData', JSON.stringify({ token: 'list-place' }), { maxAge: SECONDS.HOUR, path: '/' });
  };

  const handleFindPlaceAuth = () => {
    setCookie('authData', JSON.stringify({ token: 'find-place' }), { maxAge: SECONDS.HOUR, path: '/' });
  };

  return (
    <section className={cx(styles.createListingSection, !isShow && styles.createListingSection__hidden)}>
      <div className={styles.createListingSection_content}>
        <div className={cx(styles.createBanner, styles.listPlace)}>
          <Image
            className={styles.listPlace}
            src="/images/create-listing/sofa.webp"
            alt="list a place"
            fill
            priority
            loading="eager"
          />
          <Link
            className={styles.createListingSection_button}
            onClick={handleListPlaceAuth}
            href={listPlaceAppFlyerLink}
          >
            {t('list-a-place')}
          </Link>
        </div>

        <div className={cx(styles.createBanner, styles.findPlace)}>
          <Image
            className={styles.listPlace}
            src="/images/create-listing/girl.webp"
            alt="list a place"
            fill
            priority
            loading="eager"
          />
          <Link
            className={styles.createListingSection_button}
            onClick={handleFindPlaceAuth}
            href={findPlaceAppFlyerLink}
          >
            {t('find-a-place')}
          </Link>
        </div>
      </div>
    </section>
  );
}
